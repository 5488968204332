import { useEffect, useRef, useCallback } from 'react';

type UseEscapeHook = (onEscape: () => void) => void;

export const useEscape: UseEscapeHook = (onEscape) => {
    const onEscapeRef = useRef(onEscape);

    useEffect(() => {
        onEscapeRef.current = onEscape;
    }, [onEscape]);

    const handleEsc = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onEscapeRef.current();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleEsc, { capture: true });
        return () => window.removeEventListener('keydown', handleEsc, { capture: true });
    }, [handleEsc]);
};
